html {
  scroll-behavior: smooth;
}

:root {
  --primary: #b76209;
  --white: #ffffff;
}

body {
  font-family: "Raleway", sans-serif !important;
}

.menu {
  margin-left: 30px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.menu:hover {
  color: var(--primary);
}

/* ------------------SLIDER CSS---------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 700px !important;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: #333;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  display: none;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

/* --------------------Header---------------------- */

.service-box {
  background: #fff5eb;
  padding: 3em;
  margin-bottom: 2em;
  border-bottom: 12px solid #ffd2a4;
  padding-bottom: 2em;
}

.service-box p {
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.service-box:hover .img-ser img {
  transform: scale(0.7);
}

.service-box:hover {
  -webkit-box-shadow: 3.346px 3.716px 22.5px rgb(0 0 0/7%);
  box-shadow: 3.346px 3.716px 22.5px rgb(0 0 0/7%);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.img-ser {
  background: #ffd2a4;
  border-radius: 50%;
  padding: 8px;
  width: 70px;
  height: 70px;
  display: block;
  margin-bottom: 20px;
}

.img-ser img {
  transform: scale(1);
  transform-origin: center;
  transform-style: preserve-3d;
  transition: all 0.4s cubic-bezier(0.62, 0.21, 0.45, 1.52);
}

.img-ser img {
  width: 100%;
}

.read-more {
  color: #de8d38;
  font-size: 18px;
}

.btn-gradient {
  color: #fff;
  background-size: 200% auto;
  border: none !important;
  border-radius: 8px !important;
  background: #de8d38 !important;
  padding: 13px 30px !important;
  font-size: 18px !important;
  float: none !important;
  display: inline-block !important;
  margin-bottom: 3em !important;
}

.feature-bottom {
  padding: 30px;
  text-align: left;
  z-index: 1;
  bottom: 0;
  position: absolute;
  right: 0;
  left: 0;
  font-size: 21px;
  color: #fff;
  font-weight: 600;
}

.event-s:before {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  bottom: 0;
  height: 100%;
  -webkit-transition: height 0.5s ease-out;
  transition: height 0.5s ease-out;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(45%, transparent),
    to(#d77611)
  );
  background-image: linear-gradient(to bottom, transparent 42%, #d77611 100%);
}

.join-bg {
  /* background-image: url(./assets/images/join-bg.png); */
  padding: 10em 12em;
  display: block;
  width: 100%;
  float: left;
  background-position: top center;
}

.min-bg-w {
  background-color: #fff;
  padding: 3em;
}

.event-det {
  background: #f2994a;
  padding: 3em;
  width: 40%;
}

.footer-min {
  background-color: #222429;
}

ul {
  list-style-type: none;
  padding-left: 0px !important;
}

.text-orange {
  color: #de8d38;
}

.app-height {
  height: calc(100vh - 105px);
  overflow: auto;
}

.bookbutton {
  font-size: 18px;
  background-color: #ff7722;
  padding: 5px;
  top: 3px;
  right: 3px;
  color: white;
  border-radius: 3px;
}

.bookbuttonbig {
  font-size: 25px;
  background-color: #ff7722;
  padding: 10px;
  top: 3px;
  right: 3px;
  color: white;
  border-radius: 3px;
  font-weight: 600;
}

.icon-image {
  width: 16px;
  height: 16px;
}

.custom-dropdown .dropdown-menu {
  width: 200px; /* Set your desired width */
}

.text-indian-saffron {
  color: #ff7722;
}
.spinner-border {
  height: 50px !important;
  width: 50px !important;
  color: #ff7722;
}
